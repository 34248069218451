import { useEffect, useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { motion } from 'framer-motion'
import Arrow from '../../components/arrow/Arrow'
import { containerVariants } from '../../animations/variants'
import styles from './styles/Main.module.scss'

export const MainPage = () => {
  const [localTime, setLocalTime] = useState('')

  const width = useWindowWidth()

  useEffect(() => {
    const updateTime = () => {
      const options = {
        timeZone: 'Europe/Moscow',
      }
      const now = new Date().toLocaleTimeString('ru-RU', options)
      setLocalTime(now)
    }

    const intervalId = setInterval(updateTime, 1000)
    updateTime()

    return () => clearInterval(intervalId)
  }, [])

  

  return (
    <main className={styles.main}>
      <motion.section
        className={styles.titleContainer}
        variants={containerVariants}
        custom={3}
        initial="initial"
        animate="animate"
      >
        <h1 className={styles.title}>
          Разработка{width < 767 && <br />} & Автоматизация
          <br />
          <span>Digital-продуктов</span>
        </h1>
      </motion.section>
      <motion.section
        className={styles.textContainer}
        variants={containerVariants}
        custom={4}
        initial="initial"
        animate="animate"
      >
        <span>ТОП-5 НА HABR</span>
        <span>{localTime} KAZ</span>
        <span>200+ проектов</span>
      </motion.section>
      <motion.section
        className={styles.projects}
        variants={containerVariants}
        custom={4}
        initial="initial"
        animate="animate"
      >
        <div className={styles.project__container}>
          <div className={styles.project}>
            <p className={styles.project__heading}>
              Ubibat. Сайт для люксового проекта
              <br /> Москвы. Десять из десяти
            </p>
            <div className={styles.project__items}>
              <Arrow />
              <div className={styles.project__info}>
                <span>E-COMMERCE</span>
                <span>2024</span>
              </div>
            </div>
          </div>
          <div className={styles.project__img} />
        </div>
        <div className={styles.navigationInfo}>
          Используйте меню для навигации
        </div>
      </motion.section>
    </main>
  )
}
