import { useEffect } from 'react'
import styles from './styles/Logo.module.scss'

export const Logo = ({ isPromo }) => {
  // для анимации
  useEffect(() => {
    const paths = document.querySelectorAll(`.${styles.logo_promo} path`)

    paths.forEach((path, index) => {
      setTimeout(() => {
        path.classList.add(styles.visible)
      }, index * 100)
    })
  }, [])

  return (
    <svg
      className={isPromo ? `${styles.logo} ${styles.logo_promo}` : styles.logo}
      viewBox="0 0 104 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.65396 15.5C5.46995 15.5 3.64333 14.7829 2.17408 13.3488C0.724694 11.8953 0 10.1124 0 8C0 5.8876 0.724694 4.11434 2.17408 2.68023C3.62347 1.22674 5.39054 0.5 7.47527 0.5C9.67914 0.5 11.4363 1.25581 12.7467 2.76744C14.0769 4.25969 14.7421 6.20736 14.7421 8.61047H2.65059C2.76972 9.96705 3.3058 11.062 4.25882 11.8953C5.2317 12.7287 6.36341 13.1453 7.65396 13.1453C9.36146 13.1453 10.8109 12.564 12.0021 11.4012L13.6401 13.1453C12.1113 14.7151 10.1159 15.5 7.65396 15.5ZM7.32636 2.79651C6.31378 2.79651 5.37068 3.10659 4.49708 3.72674C3.62347 4.32752 3.05761 5.22868 2.7995 6.43023H11.8532C11.6745 5.26744 11.1682 4.36628 10.3343 3.72674C9.50045 3.08721 8.49779 2.77713 7.32636 2.79651Z" />
      <path d="M28.1242 15.2674V13.814C26.8535 14.938 25.275 15.5 23.3888 15.5C21.2048 15.5 19.3881 14.7829 17.9387 13.3488C16.5092 11.9147 15.7944 10.1318 15.7944 8C15.7944 5.86822 16.5092 4.08527 17.9387 2.65116C19.3881 1.21705 21.2048 0.5 23.3888 0.5C25.275 0.5 26.8535 1.06202 28.1242 2.18605V0.732559H30.6854V15.2674H28.1242ZM23.4484 13.1453C24.4809 13.1453 25.4041 12.9322 26.2181 12.5058C27.0322 12.0795 27.6675 11.5078 28.1242 10.7907V5.2093C27.6675 4.49225 27.0322 3.92054 26.2181 3.49419C25.4041 3.06783 24.4809 2.85465 23.4484 2.85465C21.999 2.85465 20.7978 3.34884 19.8448 4.33721C18.9116 5.3062 18.445 6.52713 18.445 8C18.445 9.47287 18.9116 10.7035 19.8448 11.6919C20.7978 12.6609 21.999 13.1453 23.4484 13.1453Z" />
      <path d="M36.6474 15.2674L41.7699 9.10465H31.7632L38.8215 0.732559H41.889L36.707 6.86628H46.7137L39.7149 15.2674H36.6474Z" />
      <path d="M50.6866 15.2674V9.27907L45.028 0.732559H47.9466L51.9672 7.01163L55.9878 0.732559H58.9064L53.2478 9.27907V15.2674H50.6866Z" />
      <path d="M60.0286 15.2674V0.732559H65.0915C67.4741 0.732559 69.3503 1.42054 70.7203 2.79651C72.1101 4.17248 72.805 5.90698 72.805 8C72.805 10.093 72.1101 11.8275 70.7203 13.2035C69.3503 14.5795 67.4741 15.2674 65.0915 15.2674H60.0286ZM65.1511 3.08721H62.5898V12.9128H65.1511C66.6997 12.9128 67.9307 12.4477 68.844 11.5174C69.7573 10.5872 70.214 9.41473 70.214 8C70.214 6.58527 69.7573 5.41279 68.844 4.48256C67.9307 3.55233 66.6997 3.08721 65.1511 3.08721Z" />
      <path d="M81.7366 15.5C79.5526 15.5 77.726 14.7829 76.2567 13.3488C74.8073 11.8953 74.0826 10.1124 74.0826 8C74.0826 5.8876 74.8073 4.11434 76.2567 2.68023C77.7061 1.22674 79.4732 0.5 81.5579 0.5C83.7618 0.5 85.5189 1.25581 86.8293 2.76744C88.1596 4.25969 88.8247 6.20736 88.8247 8.61047H76.7332C76.8524 9.96705 77.3884 11.062 78.3414 11.8953C79.3143 12.7287 80.446 13.1453 81.7366 13.1453C83.4441 13.1453 84.8935 12.564 86.0848 11.4012L87.7228 13.1453C86.194 14.7151 84.1986 15.5 81.7366 15.5ZM81.409 2.79651C80.3964 2.79651 79.4533 3.10659 78.5797 3.72674C77.7061 4.32752 77.1402 5.22868 76.8821 6.43023H85.9358C85.7572 5.26744 85.2509 4.36628 84.417 3.72674C83.5831 3.08721 82.5804 2.77713 81.409 2.79651Z" />
      <path d="M96.1078 15.5L88.2453 0.732559H91.1342L96.1078 10.5581L101.111 0.732559H104L96.1078 15.5Z" />
    </svg>
  )
}
