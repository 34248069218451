// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("embedded-opentype");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.Loader_hero__Z9Fbh {
  width: 100%;
  height: 100%;
  z-index: 5000;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
  background-color: #fff;
}
.Loader_hero__Z9Fbh.Loader_hidden__-ORHu {
  opacity: 0;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.scss","webpack://./src/components/loader/styles/Loader.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,wEAAA;EAEA,4DAAA;EACA,2DAAA;EACA,+DAAA;ACAF;ADGA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,+DAAA;ACDF;AAXA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,sBCfmB;AD4BrB;AAXE;EACE,UAAA;EACA,oBAAA;AAaJ","sourcesContent":["@font-face {\n  font-family: 'Graphik LCG';\n  font-style: normal;\n  font-weight: 400;\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot?#iefix)\n    format('embedded-opentype');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf) format('woff2');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff) format('woff');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'Roboto Mono';\n  font-style: normal;\n  font-weight: 400;\n  src: url(../assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf)\n    format('truetype');\n}\n","@import '../../../styles/variables.scss';\n@import '../../../styles/fonts.scss';\n\n.hero {\n  width: 100%;\n  height: 100%;\n  z-index: 5000;\n  padding: 20px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: opacity 0.2s ease-in-out;\n  background-color: $background-primary;\n\n  &.hidden {\n    opacity: 0;\n    pointer-events: none;\n  }\n}\n","$background-primary: #fff;\n$background-secondary: #000;\n$background-tertiary: #e8e8e8;\n$textColor-secondary: rgba(0, 0, 0, 0.48);\n$textColor-tertiary: rgba(255, 255, 255, 0.48);\n$borderColor-primary: rgba(0, 0, 0, 0.09);\n$borderColor-secondary: rgba(255, 255, 255, 0.09);\n$buttonColor-secondary: #2036fd;\n\n$transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1.4);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `Loader_hero__Z9Fbh`,
	"hidden": `Loader_hidden__-ORHu`
};
export default ___CSS_LOADER_EXPORT___;
