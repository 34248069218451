// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("embedded-opentype");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.Breadcrumbs_breadcrumbs__iKGxJ {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 11px;
  line-height: 109%;
}
.Breadcrumbs_breadcrumbs__iKGxJ a {
  color: #000;
}
.Breadcrumbs_breadcrumbs__iKGxJ span {
  color: rgba(0, 0, 0, 0.48);
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.scss","webpack://./src/components/breadcrumbs/styles/Breadcrumbs.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,wEAAA;EAEA,4DAAA;EACA,2DAAA;EACA,+DAAA;ACAF;ADGA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,+DAAA;ACDF;AAXA;EACE,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAaF;AAXE;EACE,WCTmB;ADsBvB;AAVE;EACE,0BCXkB;ADuBtB","sourcesContent":["@font-face {\n  font-family: 'Graphik LCG';\n  font-style: normal;\n  font-weight: 400;\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.eot?#iefix)\n    format('embedded-opentype');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf) format('woff2');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.woff) format('woff');\n  src: url(../assets/fonts/GraphikLCG/GraphikLCG-Regular.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'Roboto Mono';\n  font-style: normal;\n  font-weight: 400;\n  src: url(../assets/fonts/Roboto_Mono/RobotoMono-Regular.ttf)\n    format('truetype');\n}\n","@import '../../../styles/variables.scss';\n@import '../../../styles/fonts.scss';\n\n.breadcrumbs {\n  font-family: 'Roboto Mono';\n  font-weight: 400;\n  font-size: 11px;\n  line-height: 109%;\n\n  & a {\n    color: $background-secondary;\n  }\n\n  & span {\n    color: $textColor-secondary;\n  }\n}\n","$background-primary: #fff;\n$background-secondary: #000;\n$background-tertiary: #e8e8e8;\n$textColor-secondary: rgba(0, 0, 0, 0.48);\n$textColor-tertiary: rgba(255, 255, 255, 0.48);\n$borderColor-primary: rgba(0, 0, 0, 0.09);\n$borderColor-secondary: rgba(255, 255, 255, 0.09);\n$buttonColor-secondary: #2036fd;\n\n$transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1.4);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `Breadcrumbs_breadcrumbs__iKGxJ`
};
export default ___CSS_LOADER_EXPORT___;
