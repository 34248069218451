export const menu = [
  {
    id: '01',
    title: 'О нас',
    path: '/about',
  },
  {
    id: '02',
    title: 'Кейсы',
    path: '/cases',
  },
  {
    id: '03',
    title: 'Экспертность',
    path: '/services',
  },
  {
    id: '04',
    title: 'Вакансии',
    path: '/vacancy',
  },
  {
    id: '05',
    title: 'Позвонить',
    path: '/',
  },
]
